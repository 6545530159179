<section class="list-benefits">
  <div class="list-benefits__wrapper">
    <h1 class="list-benefits__title">
      Veja todas as vantagens e diferencias
      que oferecemos para você!
    </h1>

    <ul class="list-benefits__list">
      <li *ngFor="let item of benefits">
        <div class="item-benefits">
          <img class="item-benefits__image" [src]="item.src" [alt]="item.alt">
          <p class="item-benefits__description">
            {{ item.description }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</section>
