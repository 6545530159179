import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-network',
  templateUrl: './service-network.component.html',
  styleUrls: ['./service-network.component.scss'],
})
export class ServiceNetworkComponent implements OnInit {
  loading: boolean;
  clinics: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.scrollToTop();
  }

  scrollToTop() {
    document
      .querySelector('.dd-l-header-slim')
      .scrollIntoView({ behavior: 'smooth' });
  }

  searchClinics({ loading, clinics }) {
    this.loading = loading;
    this.clinics = clinics;
  }
}
