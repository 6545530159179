import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface ViaCepPayload {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}
interface Clinic {
  address: string;
  distance: number;
  id: string;
  latitude: number;
  longitude: number;
  phone: string;
  specialties: string;
  title: string;
}
@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  constructor(private http: HttpClient) {}

  getClinics(
    specialty: string = ' ',
    state: string = ' ',
    city: string = ' '
  ): Observable<any> {
    return this.http.get(
      `${environment.urlApi}/v1/general/lists/merchants?per_page=0&exploded=1&
      specialty=${specialty}&uf=${state}&city=${city}`
    );
  }

  removeFirstWord(Address): string {
    return Address.split(' ').slice(1, Address.length).join(' ');
  }

  getClinicsByZipCode(zipcode: string, specialty = ''): Observable<any> {
    return this.http.get(
      `${environment.urlApi}/v1/general/lists/merchants?per_page=0&exploded=1&specialty=${specialty}&zipcode=${zipcode}`
    );
  }
}
