import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-item',
  templateUrl: './clinic-item.component.html',
  styleUrls: ['./clinic-item.component.scss'],
})
export class ClinicItemComponent implements OnInit {
  @Input() title: string;
  @Input() address: string;
  @Input() description: string;
  @Input() distance: number;

  constructor() {}
  ngOnInit(): void {}
}
