<form class="form-clinic__form" (submit)="submit()" #form="ngForm">
  <app-text
    name="address"
    #state="ngModel"
    [(ngModel)]="clinic.zipcode"
    placeholder="CEP"
    mask="99999-999"
    class="small"
  ></app-text>

  <app-select
    *ngIf="(specialties$ | async)?.data as data"
    name="specialty"
    #specialty="ngModel"
    [(ngModel)]="clinic.specialty"
    [options]="data"
    placeholder="Especialidades"
    bindLabel="name"
    bindValue="slug"
  ></app-select>

  <button class="form-clinic__submit" type="submit">
    <span> Pesquisar</span>
    <app-search></app-search>
  </button>
  <button class="form-clinic__submit" type="reset">
    <span>Limpar</span>
    <app-back-space></app-back-space>
  </button>
</form>
