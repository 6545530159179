<section class="table-price">
  <div class="table-price__wrapper">
    <header class="table-price__header">
      <h2 class="table-price__title">Veja o quanto você  vai economizar</h2>
      <p class="table-price__description">
        Este são apenas alguns exemplos da economia que podemos lhe proporcionar:
      </p>
    </header>

    <div class="table-price__table">
      <app-table></app-table>
    </div>
  </div>
</section>
