<div id="app">
  <app-header-slim></app-header-slim>
  <main>
    <app-form-clinic (submitted)="searchClinics($event)">
      <p class="service-network__description">Pelo CEP e especialidade:</p>
      <app-form-address (submitted)="searchClinics($event)"></app-form-address>
    </app-form-clinic>

    <section class="service-network">
      <div
        class="service-network__content"
        [ngClass]="{ 'is-loading': loading }"
      >
        <app-table-clinics
          *ngIf="clinics[0] && !loading"
          [clinics]="clinics"
        ></app-table-clinics>
        <h2 class="service-network__title" *ngIf="!clinics[0] && !loading">
          O <strong>Cartão Saúde Confiança - Vale Saúde Sempre</strong>
          conta com uma rede de médicos, clínicas e laboratórios de qualidade.
        </h2>
      </div>
    </section>
  </main>
  <app-footer-contact></app-footer-contact>
</div>
