<article class="plan">
  <header class="plan__header">
    <ng-content select="[header]">
    </ng-content>
  </header>

  <div class="plan__content">
    <ng-content>
    </ng-content>
  </div>

  <div *ngIf="division" class="plan__under">
    <ng-content select="[division]">
    </ng-content>
  </div>

  <footer class="plan__footer">
    <ng-content select="[footer]">
    </ng-content>
  </footer>
</article>
