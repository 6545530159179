<div class="card-how-works">
  <div class="card-how-works__wrapper">
    <img class="card-how-works__image" [src]="image" [alt]="alt" />

    <div class="card-how-works__content">
      <h4 class="card-how-works__title">
        <ng-content></ng-content>
      </h4>
      <p class="card-how-works__description">
        <ng-content select="[description]"></ng-content>
      </p>
    </div>
  </div>
</div>
