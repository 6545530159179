<section class="main-banner">
  <div class="main-banner__wrapper">
    <header class="main-banner__header">
      <a class="main-banner__button" href="https://app.valesaudesempre.com.br/">
        Área do cliente
      </a>
    </header>

    <div class="main-banner__container">
      <h2 class="main-banner__subtitle">
        Cuide da saúde da sua família com qualidade e muita economia.
      </h2>

      <div class="main-banner__content">
        <p>
          Com o Cartão Saúde Confiança, você e seus dependentes podem realizar
          consultas médicas, exames laboratoriais e de imagens de qualidade por
          preços baixos.
        </p>

        <p>
          E mais! Você também poderá ter acesso a um atendimento sem precisar
          sair de casa:
        </p>
      </div>
      <div class="main-banner__footer">
        <div class="main-banner__card main-banner__card--positive">
          Atendimentos com médicos e enfermeiros por telefone
        </div>

        <div class="main-banner__card main-banner__card--balanced">
          Consulta online <br />
          a partir de R$70,00
        </div>
      </div>
    </div>
    <img
      class="main-banner__image"
      src="/assets/images/banners/familia_banner.png"
      alt="familia"
    />
  </div>
</section>
