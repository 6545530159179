<div class="input-controler">
  <input
    [(ngModel)]="value"
    [name]="id"
    [id]="id"
    [type]="type ? type : 'text'"
    [placeholder]="placeholder ? placeholder : ''"
    [ngClass]="{ 'has-value': val }"
    [mask]="mask"
  />
</div>
