import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-network',
  templateUrl: './item-network.component.html',
  styleUrls: ['./item-network.component.scss']
})
export class ItemNetworkComponent implements OnInit {
  @Input() title: string = "";
  @Input() link: string = "";

  constructor() { }

  ngOnInit(): void {
  }
}
