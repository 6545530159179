import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-how-works',
  templateUrl: './card-how-works.component.html',
  styleUrls: ['./card-how-works.component.scss']
})
export class CardHowWorksComponent implements OnInit {
  @Input() image: string = "";
  @Input() alt: string = "";
  @Input() description: string = "";

  constructor() {

  }

  ngOnInit(): void {
  }
}
