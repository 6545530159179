<header class="dd-l-header-slim">
  <div class="dd-l-header-slim__top-bar">Central de atendimento: 3003 0256</div>
  <div class="dd-l-header-slim__wrapper">
    <div class="dd-l-header-slim__content">
      <picture>
        <source
          media="(min-width: 768px)"
          srcset="/assets/images/logos/cartao-confianca-branco.png"
        />
        <img
          src="/assets/images/logos/cartao-confianca.svg"
          alt="Cartão Confiança"
          class="dd-l-header-slim__logo"
        />
      </picture>

      <a routerLink="/" class="dd-l-header-slim__back">
        <app-arrow-left></app-arrow-left>
        voltar
      </a>
    </div>
  </div>
</header>
