import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ServiceNetworkComponent } from './pages/service-network/service-network.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { PharmacyNetworkComponent } from './pages/pharmacy-network/pharmacy-network.component';
import { InternalHeaderComponent } from './components/internal-header/internal-header.component';
import { MainBannerComponent } from './components/sections/main-banner/main-banner.component';
import { ListBenefitsComponent } from './components/sections/list-benefits/list-benefits.component';
import { HowWorksComponent } from './components/sections/how-works/how-works.component';
import { ItemHowWorksComponent } from './components/item-how-works/item-how-works.component';
import { CardHowWorksComponent } from './components/card-how-works/card-how-works.component';
import { TablePriceComponent } from './components/sections/table-price/table-price.component';
import { TableComponent } from './components/table/table.component';
import { PlansComponent } from './components/sections/plans/plans.component';
import { PlanComponent } from './components/plan/plan.component';
import { CheckComponent } from './components/icons/check/check.component';
import { BannerFilledComponent } from './components/banner-filled/banner-filled.component';
import { NetworksComponent } from './components/sections/networks/networks.component';
import { ItemNetworkComponent } from './components/item-network/item-network.component';
import { CardInfoComponent } from './components/card-info/card-info.component';
import { HeaderSlimComponent } from './components/header-slim/header-slim.component';
import { ArrowLeftComponent } from './components/icons/arrow-left/arrow-left.component';
import { BannerPharmacyComponent } from './components/sections/banner-pharmacy/banner-pharmacy.component';
import { FooterContactComponent } from './components/footer-contact/footer-contact.component';
import { WhatsappComponent } from './components/icons/whatsapp/whatsapp.component';
import { PhoneComponent } from './components/icons/phone/phone.component';
import { FormClinicComponent } from './components/form-clinic/form-clinic.component';
import { SearchComponent } from './components/icons/search/search.component';
import { SelectComponent } from './components/inputs/select/select.component';
import { HttpClientModule } from '@angular/common/http';
import { TableClinicsComponent } from './components/table-clinics/table-clinics.component';
import { ClinicItemComponent } from './components/clinic-item/clinic-item.component';
import { FormAddressComponent } from './components/forms/form-address/form-address.component';
import { TextComponent } from './components/inputs/text/text.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { DistancePipe } from './pipes/distance/distance.pipe';
import { AddressPipe } from './pipes/address/address.pipe';
import { ErasedComponent } from './components/icons/erased/erased.component';
import { BackSpaceComponent } from './components/icons/back-space/back-space.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServiceNetworkComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    PharmacyNetworkComponent,
    InternalHeaderComponent,
    MainBannerComponent,
    ListBenefitsComponent,
    HowWorksComponent,
    ItemHowWorksComponent,
    CardHowWorksComponent,
    TablePriceComponent,
    TableComponent,
    PlansComponent,
    PlanComponent,
    CheckComponent,
    BannerFilledComponent,
    NetworksComponent,
    ItemNetworkComponent,
    CardInfoComponent,
    HeaderSlimComponent,
    ArrowLeftComponent,
    BannerPharmacyComponent,
    FooterContactComponent,
    WhatsappComponent,
    PhoneComponent,
    FormClinicComponent,
    SearchComponent,
    SelectComponent,
    TableClinicsComponent,
    ClinicItemComponent,
    FormAddressComponent,
    TextComponent,
    DistancePipe,
    AddressPipe,
    ErasedComponent,
    BackSpaceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    ScullyLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
