import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-how-works',
  templateUrl: './item-how-works.component.html',
  styleUrls: ['./item-how-works.component.scss']
})
export class ItemHowWorksComponent implements OnInit {
  @Input() image: string = "";
  @Input() alt: string = "";
  @Input() description: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
