<app-header-slim></app-header-slim>
<main>
  <app-banner-pharmacy></app-banner-pharmacy>
  <section class="pharmacy-banner__content">
    <p class="pharmacy-banner__jumbotron">
      Para ter acesso ao desconto em medicamentos primeiramente você precisa
      ativar este benefício. Entre na Área do Cliente
      <a href="https://app.valesaudesempre.com.br/">(clicando aqui)</a>
      ou acesse o Aplicativo Vale Saúde Sempre e clique no botão Rede de
      Farmácias para dar o aceite no Termo de Consentimento. Em 2 dias úteis
      você terá acesso ao desconto em medicamentos.
    </p>
    <div class="frame">
      <iframe
        [ngClass]="{ hidden: !show }"
        (load)="showIframe()"
        frameborder="0"
        src="https://www.vidalink.com.br/LocalizadorFarmaciaMedicamento/LocalizadorFarmacia.aspx?convenio=CT000566"
      >
      </iframe>
    </div>
  </section>
</main>
<app-footer-contact></app-footer-contact>
