import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClinicService } from 'src/app/services/clinic/clinic.service';
import { SpecialtyService } from 'src/app/services/specialty/specialty.service';

interface ClinicFormSubmitted {
  loading: boolean;
  clinics: any[];
}
@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss'],
})
export class FormAddressComponent implements OnInit {
  @Output() submitted: EventEmitter<ClinicFormSubmitted> = new EventEmitter();
  specialties$ = this.specialtyService.getSpecialties();
  clinic: any = {};

  constructor(
    private specialtyService: SpecialtyService,
    private clinicService: ClinicService
  ) {}

  ngOnInit(): void {}

  showLoading() {
    this.submitted.emit({
      loading: true,
      clinics: [],
    });
  }

  hideLoading(clinics = []) {
    this.submitted.emit({
      loading: false,
      clinics,
    });
  }

  submit() {
    const { zipcode, specialty } = this.clinic;

    if (!zipcode) return;

    this.showLoading();

    this.clinicService
      .getClinicsByZipCode(zipcode, specialty)
      .subscribe((clinics) => {
        this.submitted.emit({
          loading: false,
          clinics: clinics?.data,
        });
      });
  }
}
