import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    return value ? `${value.toFixed(2)} Km` : '';
  }
}
