import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-slim',
  templateUrl: './header-slim.component.html',
  styleUrls: ['./header-slim.component.scss']
})
export class HeaderSlimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
