<table class="table">
  <thead>
    <tr>
      <th></th>
      <th>Particular (média)</th>
      <th>
        <small>Cartão Saúde Confiança</small>
        Vale Saúde Sempre
      </th>
      <th>
        Economia
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Consulta Médica</td>
      <td>R$250,00</td>
      <td>R$60,00 a R$100,00</td>
      <td>R$170,00</td>
    </tr>
    <tr>
      <td>
        Hemograma
        <small>a partir</small>
      </td>
      <td>R$46,24</td>
      <td>R$9,00</td>
      <td>R$37,24</td>
    </tr>
    <tr>
      <td>
        Urina Tipo 1
        <small>a partir</small>
      </td>
      <td>R$43,15</td>
      <td>R$9,64</td>
      <td>R$33,51</td>
    </tr>
    <tr>
      <td>
        Eletrocardiograma repouso
        <small>a partir</small>
      </td>
      <td>R$121,00</td>
      <td>R$18,00</td>
      <td>R$103,00</td>
    </tr>
    <tr>
      <td>
        USG Abdômen Total
        <small>a partir</small>
      </td>
      <td>R$233,99</td>
      <td>R$82,03</td>
      <td>R$151,96</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="4">
        Os valores da tabela acima estão sujeitos a alteração sem prévio aviso.
      </td>
    </tr>
  </tfoot>
</table>
