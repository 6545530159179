import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrow-left',
  templateUrl: './arrow-left.component.html',
  styleUrls: ['./arrow-left.component.scss']
})
export class ArrowLeftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
