<section class="networks">
  <div class="networks__wrapper">
    <header class="networks__header">
      <app-item-network link="/rede-de-atendimento" title="Rede de atendimento">
        O Cartão Saúde Confiança coloca ao seu alcance uma Rede de Atendimento
        de qualidade com mais de 3.000 tipos de exames e consultas com 50
        especialidades diferentes.
      </app-item-network>

      <app-item-network link="/rede-de-farmacias" title="Rede de farmácias">
        Você e seus dependentes têm acesso a até 50% de desconto na compra de
        medicamentos em milhares de farmácias credenciadas.

        <span class="subtitle">
          É necessário ativar este beneficio antes do uso.
          <a [routerLink]="['/rede-de-farmacias']">Saiba Mais</a>
        </span>
      </app-item-network>
    </header>

    <div class="networks__content">
      <img src="/assets/images/cartao.svg" alt="Cartão Vale Saúde" />
      <div class="networks__container">
        <app-card-info>
          <span header>Cartão Saúde Confiança - Essencial</span>
          <div class="networks__title">
            <strong
              >por apenas <span class="price">R$ 24,90</span> ao mês</strong
            >
            debitados do seu Cartão Confiança
          </div>
        </app-card-info>

        <app-card-info>
          <span header>Cartão Saúde Confiança - Ligue Saúde</span>
          <div class="networks__title">
            <strong
              >por apenas <span class="price">R$ 29,90</span> ao mês</strong
            >
            debitados do seu Cartão Confiança
          </div>
        </app-card-info>
      </div>
    </div>
  </div>
</section>
