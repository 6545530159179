import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pharmacy-network',
  templateUrl: './pharmacy-network.component.html',
  styleUrls: ['./pharmacy-network.component.scss'],
})
export class PharmacyNetworkComponent implements OnInit {
  show: boolean;
  constructor() {}

  ngOnInit(): void {
    this.scrollToTop();
  }

  scrollToTop() {
    document
      .querySelector('.dd-l-header-slim')
      .scrollIntoView({ behavior: 'smooth' });
  }

  showIframe() {
    this.show = true;
  }
}
