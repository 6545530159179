<section class="banner">
  <img class="banner__image" src="/assets/images/banners/banner-pharmacy.png" alt="Rede de farmácias">
  <div class="banner__content">
    <h1 class="banner__title">Rede de farmácias</h1>
    <p class="banner__description">
      Para visualizar a Rede de Farmácias selecione abaixo o estado, a
      cidade e o bairro de sua preferência.
    </p>
  </div>
</section>
