import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() id: string;
  @Input() bindLabel: string = 'text';
  @Input() bindValue: string = 'id';
  @Input() errors: any;
  @Input() options: any[];
  @Output() change: EventEmitter<any> = new EventEmitter();

  val = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  eventChange() {
    this.change.emit(this.val)
  }

  onChange: any = (a, b) => {
  };

  onTouch: any = () => {
  };

  get value(): string {
    return this.val;
  }

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  // this method sets the value programmatically
  writeValue(value: any): void {
    this.value = value;
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
