import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-clinics',
  templateUrl: './table-clinics.component.html',
  styleUrls: ['./table-clinics.component.scss']
})
export class TableClinicsComponent implements OnInit {
  @Input() clinics: any[];
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.scroll();
    }, 250)
  }

  scroll() {
    document.querySelector('.table-clinics').scrollIntoView({behavior: 'smooth'});
  }

  scrollToTop() {
    document.querySelector('.dd-l-header-slim').scrollIntoView({behavior: 'smooth'});
  }
}
