<footer class="footer">
  <img
    src="/assets/images/logos/logo-vss.svg"
    alt="Logo da Vale Saúde Sempre"
    class="footer__logo"
  />

  <div class="footer__bottom-bar">
    <div class="footer__bottom-bar__wrapper">
      <a
        href="https://valesaudesempre.com.br/agendamento"
        class="footer__title"
      >
        Agendamento de consultas e exames
      </a>

      <a class="footer__contact" href="tel:+5530030256">
        <span class="hide-desktop">
          <app-phone></app-phone>
        </span>
        <span class="hide-mobile">
          Central de Atendimentos: <strong>3003 0256 </strong>
        </span>
      </a>

      <a
        class="footer__contact"
        href="https://api.whatsapp.com/send?phone=551130030256"
      >
        <span class="hide-desktop">
          <app-whatsapp></app-whatsapp>
        </span>
        <span class="hide-mobile">
          WhatsApp: <strong>(11) 3003-0256</strong>
        </span>
      </a>
    </div>
  </div>
</footer>
