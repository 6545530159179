<section class="how-works">
  <div class="how-works__wrapper">
    <header class="how-works__header">
      <h1 class="how-works__title">Ligue Saúde</h1>
      <p class="how-works__description">
        Em qualquer lugar, a qualquer momento, médicos e enfermeiros
        especializados em atender dúvidas de saúde e situações de urgência e
        emergência estarão aguardando sua ligação, sem custo adicional.
      </p>
    </header>

    <ul class="how-works__list">
      <li *ngFor="let item of benefits">
        <img [src]="item.image" alt="" />
        <p>{{ item.description }}</p>
      </li>
    </ul>

    <div class="how-works__content">
      <h2 class="how-works__subtitle">Como funciona?</h2>
      <ul>
        <li *ngFor="let step of steps; let i = index">
          <app-item-how-works
            [image]="step.image"
            [description]="step.description"
          >
            passo {{ i + 1 }}
          </app-item-how-works>
          <img
            *ngIf="i + 1 < steps.length"
            src="/assets/images/icons/arrow-right.svg"
            alt="seta apontando para direita"
          />
        </li>
      </ul>
      <strong class="how-works__content__footer">
        Serviço disponível dentro do App Vale Saúde Sempre ou por telefone
      </strong>
    </div>

    <footer class="how-works__footer">
      <app-card-how-works image="/assets/images/banners/telemedicina.png">
        Consulta Online
        <span description>
          E mais! Você também pode realizar uma consulta online, sem precisar
          sair de casa, de onde estiver, por um preço muito acessível.
        </span>
      </app-card-how-works>
    </footer>
  </div>
</section>
