import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "rede-de-farmacias",
    loadChildren: () => import('./pages/pharmacy-network/pharmacy-network.module').then((m) => m.PharmacyNetworkModule)
  },
  {
    path: "rede-de-atendimento",
    loadChildren: () => import('./pages/service-network/service-network.module').then((m) => m.ServiceNetworkModule)
  },
  {
    path: "**",
    redirectTo: ""
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
