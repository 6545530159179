import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-pharmacy',
  templateUrl: './banner-pharmacy.component.html',
  styleUrls: ['./banner-pharmacy.component.scss']
})
export class BannerPharmacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
