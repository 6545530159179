<section class="form-clinic">
  <img
    class="form-clinic__image"
    src="/assets/images/banners/banner-network.png"
    alt="Rede de farmácias"
  />

  <div class="form-clinic__content">
    <h1 class="form-clinic__title">Rede de Atendimento</h1>
    <p class="form-clinic__description">
      Encontre a clínica ou laboratório de sua preferência
    </p>

    <ng-content></ng-content>

    <p class="form-clinic__description">
      Ou selecione a especialidade, estado e cidade:
    </p>

    <form class="form-clinic__form" (submit)="submit()" #form="ngForm">
      <app-select
        *ngIf="(specialties$ | async)?.data as data"
        name="specialty"
        #specialty="ngModel"
        [(ngModel)]="clinic.specialty"
        [options]="data"
        placeholder="Especialidades"
        bindLabel="name"
        bindValue="slug"
      ></app-select>

      <app-select
        *ngIf="(states$ | async)?.data as data"
        name="state"
        #state="ngModel"
        [(ngModel)]="clinic.state"
        [options]="data"
        (change)="getCities()"
        placeholder="Estado"
        bindLabel="name"
        bindValue="uf"
      ></app-select>

      <app-select
        *ngIf="(states$ | async)?.data as data"
        name="city"
        #city="ngModel"
        [(ngModel)]="clinic.city"
        [options]="cities"
        placeholder="Cidade"
        (input)="getCities()"
        bindLabel="name"
        bindValue="name"
      ></app-select>

      <button class="form-clinic__submit" type="submit">
        <span> Pesquisar</span>
        <app-search></app-search>
      </button>
      <button class="form-clinic__submit" type="reset">
        <span>Limpar</span>
        <app-back-space></app-back-space>
      </button>
    </form>
  </div>
</section>
