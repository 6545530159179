<div class="card-info">
  <div class="card-info__header">
    <h5>
      <ng-content select="[header]"></ng-content>
    </h5>
  </div>
  <div class="card-info__content">
    <ng-content></ng-content>
  </div>
</div>
