import { Component } from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';
declare global {
  interface Window { __forceSmoothScrollPolyfill__: any; }
}

smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lp-cartaosaudeconfianca';
}
