import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface City {
  id: string;
  text: string;
}
@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) {}

  getCities(state: string = ' '): Observable<City> {
    return this.http.get<City>(
      `${environment.urlApi}/v1/general/lists/cities?per_page=0&scope=merchant-unit&uf=${state}`
    );
  }
}
