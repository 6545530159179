<header class="dd-l-header">
  <div class="dd-l-header__top-bar">
    <img
      class="logo"
      src="/assets/images/logos/cartao-confianca-branco.png"
      role="logo"
      alt="Logo do cartão Confiança branco"
    />

    <h4 class="dd-l-header__top-bar__title">
      Produto exclusivo para portadores do Cartão Confiança
    </h4>

    <a href="/" class="dd-l-header__logo">
      <img
        src="/assets/images/logos/vss-white.svg"
        role="logo"
        alt="Vale Saúde"
        title="Vale Saúde"
      />
    </a>
  </div>
  <div class="dd-l-header__wrapper">
    <nav>
      <ul class="dd-l-header__list">
        <li>
          <a (click)="scrollTo($event, '.dd-l-header')" href="#">início</a>
        </li>
        <li>
          <a (click)="scrollTo($event, '.list-benefits')" href="#"
            >benefícios</a
          >
        </li>
        <li>
          <a (click)="scrollTo($event, '.how-works')" href="#">ligue saúde</a>
        </li>
        <li>
          <a (click)="scrollTo($event, '.plans')" href="#">produtos</a>
        </li>
        <li>
          <a [routerLink]="['/rede-de-atendimento']">rede de atendimento</a>
        </li>
        <li>
          <a [routerLink]="['/rede-de-farmacias']">Descontos em medicamentos</a>
        </li>
        <li>
          <a href="https://app.valesaudesempre.com.br/">Área do cliente</a>
        </li>
      </ul>
    </nav>
  </div>
</header>
