import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CityService } from 'src/app/services/city/city.service';
import { ClinicService } from 'src/app/services/clinic/clinic.service';
import { SpecialtyService } from 'src/app/services/specialty/specialty.service';
import { StateService } from 'src/app/services/state/state.service';

interface ClinicFormSubmitted {
  loading: boolean;
  clinics: any[];
}
@Component({
  selector: 'app-form-clinic',
  templateUrl: './form-clinic.component.html',
  styleUrls: ['./form-clinic.component.scss'],
})
export class FormClinicComponent implements OnInit {
  @Output() submitted: EventEmitter<ClinicFormSubmitted> = new EventEmitter();
  specialties$ = this.specialtyService.getSpecialties();
  states$ = this.stateService.getStates();
  cities: any;
  clinic: any = {};

  constructor(
    private specialtyService: SpecialtyService,
    private stateService: StateService,
    private cityService: CityService,
    private clinicService: ClinicService
  ) {}

  ngOnInit(): void {}

  getCities() {
    this.cityService.getCities(this.clinic.state).subscribe((res) => {
      const data: any = res;
      this.cities = data.data;
    });
  }

  submit() {
    const { specialty, state, city } = this.clinic;

    this.submitted.emit({
      loading: true,
      clinics: [],
    });

    this.clinicService
      .getClinics(specialty, state, city)
      .subscribe((clinics) => {
        this.submitted.emit({
          loading: false,
          clinics: clinics?.data,
        });
      });
  }
}
