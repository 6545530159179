import { Component, OnInit } from '@angular/core';

class BenefitsItem {
  constructor(
    public src: string,
    public description: string,
    public alt: string
  ) {}
}

@Component({
  selector: 'app-list-benefits',
  templateUrl: './list-benefits.component.html',
  styleUrls: ['./list-benefits.component.scss'],
})
export class ListBenefitsComponent implements OnInit {
  benefits: BenefitsItem[];
  constructor() {
    this.benefits = [
      {
        src: '/assets/images/icons/doacao.svg',
        description: 'Preços muito baixos em consultas médicas.',
        alt: 'Mão segurando uma moeda',
      },
      {
        src: '/assets/images/icons/tubo-ensaio.svg',
        description:
          'Mais de 3.000 tipos de exames laboratorias e de imagem com valores reduzidos.',
        alt: 'dois tubos de ensaio',
      },
      {
        src: '/assets/images/icons/remedio.svg',
        description:
          'Até 50% de desconto em medicamentos em milhares de farmácias credenciadas em todo o Brasil.',
        alt: 'Um frasco de remdio',
      },
      {
        src: '/assets/images/icons/estetoscopio.svg',
        description: 'Rede de atendimento particular e de qualidade.',
        alt: 'Um estetoscopio',
      },
      {
        src: '/assets/images/icons/doctor.svg',
        description:
          'Tratamento de fisioterapia, acupuntura, psicologia, fonoaudiologia e nutrição a preços muitos baixos.',
        alt: 'Um médico',
      },
      {
        src: '/assets/images/icons/equipe.svg',
        description:
          'Ligue Saúde - atendimento por telefone com médicos e enfermeiros 24 horas, 7 dias da semana.',
        alt: 'Um grupo de medicos',
      },
      {
        src: '/assets/images/icons/familia.svg',
        description:
          'Cartão Familiar: titular + até 3 dependentes, sem custos adicionais.',
        alt: 'Uma família.',
      },
      {
        src: '/assets/images/icons/coracao.svg',
        description: 'Sem carência para utilização.',
        alt: 'Um coração.',
      },
      {
        src: '/assets/images/icons/gestante.svg',
        description:
          'Sem restrições para doenças preexistentes, idade e gravidez.',
        alt: 'Uma gestante com um coração na barriga.',
      },
      {
        src: '/assets/images/icons/calendario.svg',
        description: 'Serviços gratuito de agendamento de consulta e exames.',
        alt: 'Um calendario.',
      },
      {
        src: '/assets/images/icons/midias.svg',
        description: 'Consulta online por um preço muito acessível.',
        alt: 'um monitor e um celular.',
      },
      {
        src: '/assets/images/icons/maca.svg',
        description:
          'Pacotes cirúrgicos no Hospital Oswaldo Cruz - Unidade Vergueiro.',
        alt: 'uma maca',
      },
    ];
  }

  ngOnInit(): void {}
}
