<section class="plans">
  <div class="plans__wrapper">
    <h2 class="plans__title">Escolha a opção ideal para você e sua família</h2>

    <div class="plans__content">
      <app-plan>
        <div header>
          <h3 class="plans__content__title">
            <strong>Cartão Saúde Confiança</strong>
            Essencial
          </h3>
        </div>

        <div>
          <h4 class="plans__content__subtitle">Saúde</h4>
          <ul>
            <li>
              <app-check></app-check> Rede de atendimento particular e de
              qualidade.
            </li>
            <li>
              <app-check></app-check> Consultas médicas de R$60,00 a R$100,00.
            </li>
            <li>
              <app-check></app-check> Preços baixos em mais de 3.000 tipos de
              exames laboratoriais e de imagens.
            </li>
            <li>
              <app-check></app-check> Tratamentos como fisioterapia, psicologia,
              nutrição entre outros por preços baixos.
            </li>
            <li>
              <app-check></app-check> Até 50% de desconto em medicamentos nas
              farmácias credenciadas.
            </li>
            <li>
              <app-check></app-check> Cartão familiar: titular + até 3
              dependentes (cônjuge, filhos, enteados, pais, sogros, avós e
              netos), sem custos adicionais.
            </li>
            <li><app-check></app-check> Sem carência e sem restrições.</li>
            <li>
              <app-check></app-check> Serviço gratuito de agendamento de
              consultas e exames.
            </li>
            <li>
              <app-check></app-check> Consulta online a partir de R$ 70,00.
            </li>
          </ul>
        </div>

        <div footer>
          <h3 class="plans__content__title plans__content__title--footer">
            <small>por apenas</small>
            <div class="plans__price">R$ <strong>24,90</strong>/mês</div>
          </h3>
        </div>
      </app-plan>

      <app-plan [division]="true" title="Ligue Saúde">
        <div header>
          <h3 class="plans__content__title">
            <strong>Cartão Saúde Confiança</strong>
            Ligue Saúde
          </h3>
        </div>

        <div>
          <h4 class="plans__content__subtitle">Saúde</h4>
          <ul>
            <li>
              <app-check></app-check> Rede de atendimento particular e de
              qualidade.
            </li>
            <li>
              <app-check></app-check> Consultas médicas de R$60,00 a R$100,00.
            </li>
            <li>
              <app-check></app-check> Preços baixos em mais de 3.000 tipos de
              exames laboratoriais e de imagens.
            </li>
            <li>
              <app-check></app-check> Tratamentos como fisioterapia, psicologia,
              nutrição entre outros por preços baixos.
            </li>
            <li>
              <app-check></app-check> Até 50% de desconto em medicamentos nas
              farmácias credenciadas.
            </li>
            <li>
              <app-check></app-check> Cartão familiar: titular + até 3
              dependentes (cônjuge, filhos, enteados, pais, sogros, avós e
              netos), sem custos adicionais.
            </li>
            <li><app-check></app-check> Sem carência e sem restrições.</li>
            <li>
              <app-check></app-check> Serviço gratuito de agendamento de
              consultas e exames.
            </li>
          </ul>
        </div>

        <div division>
          <h4 class="plans__content__subtitle">Atendimento</h4>
          <ul>
            <li>
              <app-check></app-check> Ligue Saúde para o titular + até 3
              dependentes.
            </li>
            <li>
              <app-check></app-check> Consulta online a partir de R$ 70,00.
            </li>
          </ul>
        </div>

        <div footer>
          <h3 class="plans__content__title plans__content__title--footer">
            <small>por apenas</small>
            <div class="plans__price">R$ <strong>29,90</strong>/mês</div>
          </h3>
        </div>
      </app-plan>
    </div>
  </div>
</section>
