<app-header></app-header>
<main>
  <app-main-banner></app-main-banner>
  <app-list-benefits></app-list-benefits>
  <app-how-works></app-how-works>
  <app-table-price></app-table-price>
  <app-plans></app-plans>
  <app-banner-filled>
    Não perca tempo! Adquira já o seu Cartão Saúde Confiança - Vale Saúde Sempre
    através do seu Cartão Confiança no D'avó Supermercados mais próximo de você.
  </app-banner-filled>
  <app-networks></app-networks>
</main>
<app-footer-contact></app-footer-contact>
