<ng-select class="select"
  [placeholder]="placeholder"
  notFoundText="Nenhum item encontrado"
  (change)="eventChange()"
  [(ngModel)]="value"
  [items]="options"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [id]="id">
</ng-select>
