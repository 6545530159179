<div class="table-clinics">
  <ul class="table-clinics__header">
    <li class="description"><i class="icon-heart"></i> Especialidade</li>
    <li class="title"><i class="icon-home"></i> Nome clínica</li>
    <li class="subtitle"><i class="icon-map"></i> Endereço</li>
  </ul>
  <ul class="table-clinics__content">
    <li *ngFor="let clinic of clinics">
      <app-clinic-item
        [title]="clinic.title"
        [address]="clinic.address"
        [description]="clinic.specialties"
        [distance]="clinic.distance"
      ></app-clinic-item>
    </li>
  </ul>
  <div class="table-clinics__footer">
    <button
      (click)="scrollToTop()"
      class="table-clinics__button icon-up fixed"
    ></button>
  </div>
</div>
