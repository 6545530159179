import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss'],
})
export class HowWorksComponent implements OnInit {
  steps: any[];
  benefits: any[];

  constructor() {
    this.benefits = [
      {
        image: '/assets/images/icons/coracao.svg',
        description: 'Comodidade e sem limite de utilização.',
      },
      {
        image: '/assets/images/icons/telefone.svg',
        description:
          'Ligação gratuita de onde estiver e acesso disponível em seu App Vale Saúde Sempre.',
      },
      {
        image: '/assets/images/icons/telemarketing.svg',
        description: 'Apoio de médicos e enfermeiros capacitados.',
      },
      {
        image: '/assets/images/icons/castelo.svg',
        description: 'Diminuição de exposição ao risco. (Pronto Atendimento).',
      },
      {
        image: '/assets/images/icons/calendario.svg',
        description:
          'Atendimento 24 horas, 7 dias por semana sem necessitar de agendamento.',
      },
    ];

    this.steps = [
      {
        image: '/assets/images/icons/steps/step-1.png',
        description:
          'Acesse o App Vale Saúde Sempre através do seu <br/>e-mail e senha.',
      },
      {
        image: '/assets/images/icons/steps/step-2.png',
        description:
          'No menu do App, clique em Ligue Saúde. Se preferir você pode ligar diretamente pelo número 0800 591 6091.',
      },
      {
        image: '/assets/images/icons/steps/step-3.png',
        description:
          'Em instantes você será atendido por um profissional da saúde.',
      },
    ];
  }

  ngOnInit(): void {}
}
